import React, { useState, useEffect } from 'react';
import { Sun, Moon } from 'lucide-react';

const ThemeToggle = ({ isDarkMode, toggleTheme }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    toggleTheme();
    setTimeout(() => setIsAnimating(false), 500);
  };

  return (
    <button 
      className={`theme-toggle ${isAnimating ? 'animating' : ''}`} 
      onClick={handleClick}
      aria-label={isDarkMode ? "Passer au mode clair" : "Passer au mode sombre"}
    >
      <div className="icon-container">
        <Sun className={`sun ${isDarkMode ? 'hidden' : ''}`} />
        <Moon className={`moon ${isDarkMode ? '' : 'hidden'}`} />
      </div>
    </button>
  );
};

export default ThemeToggle;