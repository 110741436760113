import React, { useEffect, useCallback, useMemo } from 'react';
import useParkData from './hooks/useParkData';
import useSharedParkData from './hooks/useSharedParkData';
import ParkContent from './components/ParkContent';
import { useTranslation } from 'react-i18next';
import { isMobile } from './utils/deviceDetection';
import Push from 'push.js';

const API_URL = 'https://api.themeparks.wiki/v1/entity/ca888437-ebb4-4d50-aed2-d227f7096968/live';
const SCHEDULE_API_URL = 'https://api.themeparks.wiki/v1/entity/ca888437-ebb4-4d50-aed2-d227f7096968/schedule';
const CACHE_KEY = 'waltDisneyStudiosWaitTimes';
const CALENDAR_URL = 'https://themeparks.wiki/browse/ca888437-ebb4-4d50-aed2-d227f7096968/calendar';

const lands = {
  'Marvel Avengers Campus': ['Avengers Assemble: Flight Force', 'Spider-Man W.E.B. Adventure'],
  'Toon Studio': [
    'Art of Disney Animation',
    'Cars Quatre Roues Rallye',
    'Cars ROAD TRIP',
    'Crush\'s Coaster',
    'Les Tapis Volants - Flying Carpets Over Agrabah',
    'Ratatouille: The Adventure',
    'RC Racer',
    'Slinky Dog Zigzag Spin',
    'Toy Soldiers Parachute Drop'
  ],
  'Production Courtyard': ['Meet Spider-Man', 'The Twilight Zone Tower of Terror']
};

const showNotification = (message) => {
  if (isMobile()) {
    Push.create("Disney Wait Times", {
      body: message,
      icon: '/images/icon.png',
      timeout: 4000,
      onClick: function () {
        window.focus();
        this.close();
      }
    });
  } else {
    new Notification(message);
  }
};

const WaltDisneyStudios = ({ onError, waitTimeThresholds, updateLoadingState }) => {
  const { t, i18n } = useTranslation();

  const { waitTimes, lastUpdate, loading, openingHours, debouncedFetchWaitTimes } = useParkData(API_URL, SCHEDULE_API_URL, CACHE_KEY, CALENDAR_URL);

  useEffect(() => {
    updateLoadingState(loading);
  }, [loading, updateLoadingState]);

  const { favorites, notifications, toggleFavorite, toggleNotification, setWaitTimeThreshold } = useSharedParkData();

  const checkNotifications = useCallback(() => {
    waitTimes.forEach(attraction => {
      const notification = notifications[attraction.id];
      if (notification) {
        if ((notification.status === 'CLOSED' || notification.status === 'DOWN') &&
            attraction.status === 'OPEN' &&
            attraction.waitTime !== undefined &&
            attraction.waitTime >= 0) {
          const message = t('reopenNotification', { attractionName: attraction.name, waitTime: attraction.waitTime });
          console.log('Message de notification:', message);
          showNotification(message);
          toggleNotification(attraction.id, null, null, true);
        } else if (notification.waitTimeThreshold !== undefined &&
                   attraction.status === 'OPEN' &&
                   attraction.waitTime !== undefined &&
                   attraction.waitTime <= notification.waitTimeThreshold) {
          const message = t('waitTimeNotification', { attractionName: attraction.name, waitTime: attraction.waitTime });
          console.log('Message de notification:', message);
          showNotification(message);
          toggleNotification(attraction.id, null, null, true);
        }
      }
    });
  }, [waitTimes, notifications, toggleNotification, t]);

  const getStatusText = (attraction) => {
    if (attraction.status === 'CLOSED') return t('closed');
    if (attraction.status === 'DOWN') return t('outOfOrder');
    if (attraction.waitTime === -1) return t('noInfo');
    return `${attraction.waitTime} ${t('min')}`;
  };

  const getStatusClass = (attraction) => {
    if (attraction.status === 'CLOSED') return 'closed';
    if (attraction.status === 'DOWN') return 'out-of-order';
    if (attraction.waitTime <= 5) return 'very-short-wait';
    if (attraction.waitTime <= 15) return 'short-wait';
    if (attraction.waitTime <= 30) return 'medium-wait';
    return 'long-wait';
  };

  const getDownTimeDuration = (id) => {
    const downTimeStart = localStorage.getItem(`downTime_${id}`);
    if (!downTimeStart) return t('notAvailable');
    const duration = Math.floor((new Date() - new Date(downTimeStart)) / 60000);
    return t('downTimeDuration', { minutes: duration });
  };

  const formatTime = (dateString) => {
    if (!dateString) return t('notAvailable');
    const date = new Date(dateString);
    return date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit' });
  };

  const getAttractionLand = useCallback((attractionName) => {
    const normalizedName = attractionName.toLowerCase().replace(/[®™]/g, '').trim();
    for (const [land, attractions] of Object.entries(lands)) {
      if (attractions.some(attr => normalizedName.includes(attr.toLowerCase()))) {
        return land;
      }
    }
    console.warn(`Land non trouvé pour l'attraction: ${attractionName}`);
    return 'Autre';
  }, []);

  const attractionsWithLand = useMemo(() => {
    const result = waitTimes.map(attraction => {
      const land = getAttractionLand(attraction.name);
      if (land === 'Autre') {
        console.warn(`Attraction sans land assigné: ${attraction.name}`);
        console.log('Noms d\'attractions dans lands:', Object.values(lands).flat());
      }
      return { ...attraction, land };
    });
    return result;
  }, [waitTimes, getAttractionLand]);

  const requestNotificationPermission = () => {
    if (!isMobile()) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.warn("Notification permission denied.");
          }
        });
      }
    } else {
      console.log("Notifications not supported on mobile devices.");
    }
  };

  return (
    <ParkContent
      parkName="Walt Disney Studios"
      openingHours={openingHours}
      lastUpdate={lastUpdate}
      loading={loading}
      debouncedFetchWaitTimes={debouncedFetchWaitTimes}
      attractions={attractionsWithLand}
      favorites={favorites}
      notifications={notifications}
      toggleFavorite={toggleFavorite}
      toggleNotification={toggleNotification}
      setWaitTimeThreshold={setWaitTimeThreshold}
      getStatusClass={getStatusClass}
      getStatusText={getStatusText}
      getDownTimeDuration={getDownTimeDuration}
      formatTime={formatTime}
      lands={lands}
      waitTimeThresholds={waitTimeThresholds}
      requestNotificationPermission={requestNotificationPermission}
    />
  );
};

export default WaltDisneyStudios;
