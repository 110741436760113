import React, { useEffect, useCallback, useMemo } from 'react';
import useParkData from './hooks/useParkData';
import useSharedParkData from './hooks/useSharedParkData';
import ParkContent from './components/ParkContent';
import { useTranslation } from 'react-i18next';
import { isMobile } from './utils/deviceDetection';
import Push from 'push.js';

const showNotification = (message) => {
  if (isMobile()) {
    Push.create("Disney Wait Times", {
      body: message,
      icon: '/images/icon.png',
      timeout: 4000,
      onClick: function () {
        window.focus();
        this.close();
      }
    });
  } else {
    new Notification(message);
  }
};

const API_URL = 'https://api.themeparks.wiki/v1/entity/dae968d5-630d-4719-8b06-3d107e944401/live';
const SCHEDULE_API_URL = 'https://api.themeparks.wiki/v1/entity/dae968d5-630d-4719-8b06-3d107e944401/schedule';
const CACHE_KEY = 'disneylandParkWaitTimes';
const CALENDAR_URL = 'https://themeparks.wiki/browse/dae968d5-630d-4719-8b06-3d107e944401/calendar';

const lands = {
  'Adventureland': [
    'Adventure Isle',
    'Indiana Jones and the Temple of Peril',
    'La Cabane des Robinson',
    'Le Passage Enchanté d\'Aladdin',
    'Pirate Galleon',
    'Pirates of the Caribbean',
    'Pirates\' Beach'
  ],
  'Frontierland': [
    'Big Thunder Mountain',
    'Phantom Manor',
    'Thunder Mesa Riverboat Landing',
    'Disneyland Railroad Frontierland Depot',
    'Frontierland Playground',
    'River Rogue Keelboats',
    'Rustler Roundup Shootin\' Gallery'
  ],
  'Discoveryland': [
    'Autopia, presented by Avis',
    'Buzz Lightyear Laser Blast',
    'Disneyland Railroad Discoveryland Station',
    'Les Mystères du Nautilus',
    'Mickey\'s PhilharMagic',
    'Orbitron',
    'Star Tours: The Adventures Continue',
    'Star Wars Hyperspace Mountain',
    'Welcome to Starport: A Star Wars Encounter'
  ],
  'Fantasyland': [
    'It\'s a Small World', 
    'Peter Pan\'s Flight', 
    'Dumbo the Flying Elephant', 
    'Mad Hatter\'s Tea Cups', 
    'Alice\'s Curious Labyrinth', 
    'Le Carrousel de Lancelot', 
    'Les Voyages de Pinocchio', 
    'Blanche-Neige et les Sept Nains', 
    'Casey Jr. – le Petit Train du Cirque',
    'Disneyland Railroad Fantasyland Station',
    'La Tanière du Dragon',
    'Le Pays des Contes de Fées, presented by Vittel',
    'Meet Mickey Mouse',
    'Princess Pavilion'
  ],
  'Main Street, U.S.A.': [
    'Main Street Vehicles',
    'Disneyland Railroad Main Street Station'
  ],
};

const DisneylandPark = ({ onError, waitTimeThresholds, updateLoadingState }) => {
  const { waitTimes, lastUpdate, loading, openingHours, debouncedFetchWaitTimes } = useParkData(API_URL, SCHEDULE_API_URL, CACHE_KEY, CALENDAR_URL);
  
  useEffect(() => {
    updateLoadingState(loading);
  }, [loading, updateLoadingState]);

  const { favorites, notifications, toggleFavorite, toggleNotification, setWaitTimeThreshold } = useSharedParkData();
  const { t, i18n } = useTranslation();

  const getStatusText = (attraction) => {
    if (attraction.status === 'CLOSED') return t('closed');
    if (attraction.status === 'DOWN') return t('outOfOrder');
    if (attraction.waitTime === -1) return t('noInfo');
    return `${attraction.waitTime} ${t('min')}`;
  };

  const getStatusClass = (attraction) => {
    if (attraction.status === 'CLOSED') return 'closed';
    if (attraction.status === 'DOWN') return 'out-of-order';
    if (attraction.waitTime <= 5) return 'very-short-wait';
    if (attraction.waitTime <= 15) return 'short-wait';
    if (attraction.waitTime <= 30) return 'medium-wait';
    return 'long-wait';
  };

  const formatTime = (dateString) => {
    if (!dateString) return t('notAvailable');
    const date = new Date(dateString);
    return date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit' });
  };

  const getAttractionLand = useCallback((attractionName) => {
    const normalizedName = attractionName.toLowerCase().replace(/[®™']/g, '').trim();
    for (const [land, attractions] of Object.entries(lands)) {
      for (const attr of attractions) {
        const normalizedAttr = attr.toLowerCase().replace(/[®™']/g, '').trim();
        if (normalizedName === normalizedAttr || 
            normalizedName.includes(normalizedAttr) || 
            normalizedName.includes(normalizedAttr) ||
            normalizedName.replace(/\s/g, '') === normalizedAttr.replace(/\s/g, '') ||
            normalizedName.replace(/[^\w\s]/g, '') === normalizedAttr.replace(/[^\w\s]/g, '')) {
          return land;
        }
      }
    }
    return 'Autre';
  }, []);

  const attractionsWithLand = useMemo(() => {
    return waitTimes.map(attraction => ({
      ...attraction,
      land: getAttractionLand(attraction.name)
    }));
  }, [waitTimes, getAttractionLand]);

  const checkNotifications = useCallback(() => {
    waitTimes.forEach(attraction => {
      const notification = notifications[attraction.id];
      if (notification) {
        if ((notification.status === 'CLOSED' || notification.status === 'DOWN') &&
            attraction.status === 'OPEN' &&
            attraction.waitTime !== undefined &&
            attraction.waitTime >= 0 &&
            !notification.notificationSent) {
          const message = t('reopenNotification', { attractionName: attraction.name, waitTime: attraction.waitTime });
          console.log('Message de notification:', message);
          showNotification(message);
          toggleNotification(attraction.id, attraction.status, attraction.waitTime, true);
        } else if (notification.waitTimeThreshold !== undefined &&
                   attraction.status === 'OPEN' &&
                   attraction.waitTime !== undefined &&
                   attraction.waitTime <= notification.waitTimeThreshold &&
                   !notification.notificationSent) {
          console.log(`Envoi de la notification pour ${attraction.name} avec un temps d'attente de ${attraction.waitTime} minutes.`);
          showNotification(t('waitTimeNotification', { attractionName: attraction.name, waitTime: attraction.waitTime }));
          toggleNotification(attraction.id, attraction.status, attraction.waitTime, true);
        } else if (notification.notificationSent &&
                   ((attraction.status !== 'OPEN') ||
                    (notification.waitTimeThreshold !== undefined && attraction.waitTime > notification.waitTimeThreshold))) {
          // Réinitialiser le drapeau pour permettre une nouvelle notification à l'avenir
          toggleNotification(attraction.id, attraction.status, attraction.waitTime, false);
        }
      }
    });
  }, [waitTimes, notifications, toggleNotification, t]);

  useEffect(() => {
    const interval = setInterval(checkNotifications, 60000); // Vérifie toutes les minutes
    return () => clearInterval(interval);
  }, [checkNotifications]);

  const requestNotificationPermission = () => {
    if (isMobile()) {
      Push.Permission.request(() => {
        console.log("Push.js permission granted.");
      }, () => {
        console.warn("Push.js permission denied.");
      });
    } else {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.warn("Notification permission denied.");
          }
        });
      }
    }
  };

  return (
    <ParkContent
      parkName="Disneyland Park"
      openingHours={openingHours}
      lastUpdate={lastUpdate}
      loading={loading}
      debouncedFetchWaitTimes={debouncedFetchWaitTimes}
      attractions={attractionsWithLand}
      favorites={favorites}
      notifications={notifications}
      toggleFavorite={toggleFavorite}
      toggleNotification={toggleNotification}
      setWaitTimeThreshold={setWaitTimeThreshold}
      getStatusClass={getStatusClass}
      getStatusText={getStatusText}
      formatTime={formatTime}
      lands={lands}
      waitTimeThresholds={waitTimeThresholds}
      requestNotificationPermission={requestNotificationPermission}
    />
  );
};


export default DisneylandPark;
