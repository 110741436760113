import React, { useState, useRef, useCallback, useEffect } from 'react';
import { RefreshCw, Clock, Star, Bell, ChevronDown } from 'lucide-react';
import anime from 'animejs';
import CustomPrompt from './CustomPrompt';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../utils/deviceDetection';

const ParkContent = ({ 
  parkName, 
  openingHours, 
  lastUpdate, 
  loading,
  debouncedFetchWaitTimes,
  attractions,
  favorites,
  notifications,
  toggleFavorite,
  toggleNotification,
  setWaitTimeThreshold,
  getStatusClass,
  getStatusText,
  formatTime,
  lands,
  waitTimeThresholds,
  requestNotificationPermission
}) => {
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState('waitTime');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const starRefs = useRef({});
  const bellRefs = useRef({});
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [currentAttraction, setCurrentAttraction] = useState(null);
  const [promptPosition, setPromptPosition] = useState({ top: 0, left: 0 });
  const { t, i18n } = useTranslation();
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setContentLoaded(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setContentLoaded(false);
    }
  }, [loading]);

  const filteredAttractions = attractions.filter(attraction => {
    if (filter === 'favorites') return favorites.includes(attraction.id);
    if (filter === 'notifications') return notifications[attraction.id];
    return true;
  });

  const sortedAttractions = [...filteredAttractions].sort((a, b) => {
    if (sortBy === 'name') return a.name.localeCompare(b.name);
    if (sortBy === 'waitTime') return b.waitTime - a.waitTime;
    return 0;
  });


  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const getSortByText = () => {
    switch(sortBy) {
      case 'name': return t('sortBy.name');
      case 'waitTime': return t('sortBy.waitTime');
      case 'land': return t('sortBy.land');
      default: return t('sortBy.waitTime');
    }
  };

  const animateStar = (id) => {
    anime({
      targets: starRefs.current[id],
      scale: [1, 1.5, 1],
      rotate: '1turn',
      duration: 1000,
      easing: 'easeInOutQuad'
    });
  };

  const animateBell = useCallback((id) => {
    if (bellRefs.current[id]) {
      anime({
        targets: bellRefs.current[id],
        rotateZ: [0, -45, 45, -45, 45, 0],
        duration: 500,
        easing: 'easeInOutSine'
      });
    }
  }, []);


  const handleNotificationClick = useCallback((attraction, event) => {
    if (notifications[attraction.id]) {
      toggleNotification(attraction.id);
    } else {
      if (attraction.status === 'CLOSED' || attraction.status === 'DOWN') {
        requestNotificationPermission();
        toggleNotification(attraction.id, attraction.status);
      } else if (attraction.waitTime !== undefined && attraction.waitTime >= 0) {
        requestNotificationPermission();
        const rect = event.target.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        let top = rect.bottom + window.scrollY;
        
        // Ajuster la position si la fenêtre dépasse le bas de l'écran
        if (top + 300 > viewportHeight + window.scrollY) {
          top = rect.top + window.scrollY - 300;
        }
        
        setPromptPosition({
          top: top,
          left: rect.left + window.scrollX
        });
        setCurrentAttraction(attraction);
        setIsPromptOpen(true);
      }
    }
    animateBell(attraction.id);
  }, [notifications, toggleNotification, animateBell, requestNotificationPermission]);

  const handlePromptSubmit = (threshold) => {
    if (currentAttraction) {
      toggleNotification(currentAttraction.id, currentAttraction.status, currentAttraction.waitTime, false);
      setWaitTimeThreshold(currentAttraction.id, currentAttraction.name, threshold, currentAttraction.waitTime);
    }
    setIsPromptOpen(false);
  };

  const handleBellClick = useCallback((attraction, event) => {
    event.stopPropagation();
    if (notifications[attraction.id]) {
      toggleNotification(attraction.id, null, null);
    } else {
      if (attraction.status === 'CLOSED' || attraction.status === 'DOWN') {
        requestNotificationPermission();
        toggleNotification(attraction.id, attraction.status, null);
      } else if (attraction.waitTime !== undefined && attraction.waitTime >= 0) {
        requestNotificationPermission();
        const rect = event.target.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        let top = rect.bottom + window.scrollY;
        
        if (top + 300 > viewportHeight + window.scrollY) {
          top = rect.top + window.scrollY - 300;
        }
        
        setPromptPosition({
          top: top,
          left: rect.left + window.scrollX
        });
        setCurrentAttraction(attraction);
        setIsPromptOpen(true);
      }
    }
    animateBell(attraction.id);
  }, [notifications, toggleNotification, animateBell, requestNotificationPermission]);

  const renderAttractionItem = useCallback((attraction) => (
    <div key={attraction.id} className="attraction-item">
      <span className="attraction-name">{attraction.name}</span>
      <div className="attraction-right">
        <div className="icons">
          <button 
            onClick={() => {
              toggleFavorite(attraction.id);
              animateStar(attraction.id);
            }} 
            aria-label={favorites.includes(attraction.id) ? 'Retirer des favoris' : 'Ajouter aux favoris'}
            aria-pressed={favorites.includes(attraction.id)}
          >
            <Star 
              ref={el => starRefs.current[attraction.id] = el}
              className={`favorite-icon ${favorites.includes(attraction.id) ? 'active' : ''}`}
              fill={favorites.includes(attraction.id) ? 'currentColor' : 'none'}
              stroke={favorites.includes(attraction.id) ? 'currentColor' : 'currentColor'}
            />
          </button>
          {!isMobile() && (
            <button 
              onClick={(event) => handleBellClick(attraction, event)} 
              aria-label={notifications[attraction.id] ? 'Supprimer l\'alerte' : 'Ajouter une alerte'}
              aria-pressed={notifications[attraction.id]}
            >
              <Bell 
                ref={el => bellRefs.current[attraction.id] = el}
                className={`notification-icon ${notifications[attraction.id] ? 'active' : ''}`}
                fill={notifications[attraction.id] ? 'currentColor' : 'none'}
                stroke={notifications[attraction.id] ? 'currentColor' : 'currentColor'}
              />
            </button>
          )}
        </div>
        <div className="wait-time-container">
          <span 
            className={`wait-time ${getStatusClass(attraction)}`}
            aria-label={`Temps d'attente pour ${attraction.name}: ${getStatusText(attraction)}`}
          >
            {getStatusText(attraction)}
          </span>
          {attraction.singleRiderWaitTime >= 0 && (
            <span className="single-rider-wait-time">
              {t('singleRider')}: {attraction.singleRiderWaitTime} {t('min')}
            </span>
          )}
        </div>
      </div>
    </div>
  ), [notifications, handleBellClick, favorites, toggleFavorite, animateStar, getStatusClass, getStatusText, t]);

  const renderAttractions = () => {
    if (sortBy === 'land') {
      const attractionsByLand = sortedAttractions.reduce((acc, attraction) => {
        if (!acc[attraction.land]) {
          acc[attraction.land] = [];
        }
        acc[attraction.land].push(attraction);
        return acc;
      }, {});

      return Object.entries(attractionsByLand).map(([land, landAttractions]) => (
        <div key={land} className="land-group">
          <h3 className="land-title">{land}</h3>
          {landAttractions.map(attraction => renderAttractionItem(attraction))}
        </div>
      ));
    } else {
      return sortedAttractions.map(attraction => renderAttractionItem(attraction));
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(i18n.language, options);
  };

  const renderFilterButtons = () => (
    <div className="filter-buttons">
      <button
        className={`filter-button ${filter === 'all' ? 'active' : ''}`}
        onClick={() => setFilter('all')}
      >
        {t('filters.all')}
      </button>
      <button
        className={`filter-button ${filter === 'favorites' ? 'active' : ''}`}
        onClick={() => setFilter('favorites')}
      >
        {t('filters.favorites')}
      </button>
      {!isMobile() && (
        <button
          className={`filter-button ${filter === 'notifications' ? 'active' : ''}`}
          onClick={() => setFilter('notifications')}
        >
          {t('filters.alerts')}
        </button>
      )}
    </div>
  );

  return (
    <div className={`park-content ${contentLoaded ? 'loaded' : ''}`}>
      {!contentLoaded && (
        <div className="loading-overlay">
          <div className="mickey-loading">
            <div className="ear ear-left"></div>
            <div className="ear ear-right"></div>
            <div className="head"></div>
          </div>
        </div>
      )}
      {contentLoaded && (
        <>
          <div className="park-header">
            <h2 className="park-title">{parkName}</h2>
            <div className="opening-hours">
              {openingHours.error ? (
                <p className="error-message">{t('openingHours.error')}</p>
              ) : (
                <>
                  {openingHours.isFuture && (
                    <p className="future-date-notice">
                      {t('openingHours.futureDate', { date: formatDate(openingHours.date) })}
                    </p>
                  )}
                  <p>
                    {t('openingHours.hours', { 
                      openingTime: formatTime(openingHours.openingTime), 
                      closingTime: formatTime(openingHours.closingTime) 
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
          
          <div className="filters">
            <div className="filters-left">
              {renderFilterButtons()}
              <div className="last-update">
                <span className="icon">🕓</span>
                {t('common.lastUpdate', { time: formatTime(lastUpdate) })}
              </div>
            </div>
            <div className="filters-right">
              <div className="sort-dropdown">
                <button onClick={toggleDropdown} className="sort-dropdown-button">
                  {t(`sortBy.${sortBy}`)}
                  <ChevronDown className={`arrow ${isDropdownOpen ? 'up' : ''}`} />
                </button>
                {isDropdownOpen && (
                  <div className="sort-dropdown-menu">
                    <button onClick={() => { setSortBy('name'); toggleDropdown(); }}>{t('sortBy.name')}</button>
                    <button onClick={() => { setSortBy('waitTime'); toggleDropdown(); }}>{t('sortBy.waitTime')}</button>
                    {lands && <button onClick={() => { setSortBy('land'); toggleDropdown(); }}>{t('sortBy.land')}</button>}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="attraction-list">
            <div className="attractions">
              {renderAttractions()}
            </div>
          </div>
        </>
      )}
      <CustomPrompt
        isOpen={isPromptOpen}
        onClose={() => setIsPromptOpen(false)}
        onSubmit={handlePromptSubmit}
        message={t('alertPrompt.message', { attractionName: currentAttraction?.name })}
      />
    </div>
  );
};


export default ParkContent;
