import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useSharedParkData = () => {
  const { t } = useTranslation();
  const [favorites, setFavorites] = useState([]);
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    const savedFavorites = localStorage.getItem('favorites');
    if (savedFavorites) {
      setFavorites(JSON.parse(savedFavorites));
    }

    const savedNotifications = JSON.parse(localStorage.getItem('notifications') || '{}');
    setNotifications(savedNotifications);
  }, []);

  const toggleFavorite = useCallback((attractionId) => {
    setFavorites(prev => {
      const newFavorites = prev.includes(attractionId)
        ? prev.filter(id => id !== attractionId)
        : [...prev, attractionId];
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
      return newFavorites;
    });
  }, []);

  const toggleNotification = useCallback((attractionId, status, waitTime, notificationSent = false) => {
    setNotifications(prev => {
      const newNotifications = { ...prev };
      if (newNotifications[attractionId] && (notificationSent || (!status && !waitTime))) {
        if (notificationSent) {
          newNotifications[attractionId] = { ...newNotifications[attractionId], notificationSent };
        } else {
          delete newNotifications[attractionId];
        }
      } else {
        newNotifications[attractionId] = { status, waitTime, notificationSent };
      }
      console.log('Mise à jour des notifications :', newNotifications);
      localStorage.setItem('notifications', JSON.stringify(newNotifications));
      return newNotifications;
    });
  }, []);

  const setWaitTimeThreshold = useCallback((attractionId, attractionName, threshold, currentWaitTime) => {
    console.log(`Setting wait time threshold for ${attractionName} to ${threshold}`);
    setNotifications(prev => {
      const newNotifications = { ...prev };
      if (newNotifications[attractionId]) {
        newNotifications[attractionId] = {
          ...newNotifications[attractionId],
          waitTimeThreshold: threshold,
          name: attractionName
        };
      } else {
        newNotifications[attractionId] = { status: 'OPEN', waitTimeThreshold: threshold, name: attractionName };
      }
      
      // Vérifier si le temps d'attente actuel est inférieur ou égal au seuil
      if (currentWaitTime !== undefined && currentWaitTime <= threshold) {
        console.log(`Envoi immédiat de la notification pour ${attractionName} avec un temps d'attente de ${currentWaitTime} minutes.`);
        new Notification(t('waitTimeNotification', { attractionName: attractionName, waitTime: currentWaitTime }));
        newNotifications[attractionId].notificationSent = true;
      }
      
      localStorage.setItem('notifications', JSON.stringify(newNotifications));
      return newNotifications;
    });
  }, [t]);

  return { favorites, notifications, toggleFavorite, toggleNotification, setWaitTimeThreshold };
};

export default useSharedParkData;