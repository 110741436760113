import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Remplacez l'ancienne API par la nouvelle
const API_URL = 'https://api.themeparks.wiki/v1/entity/e8d0207f-da8a-4048-bec8-117aa946b2c2/live';
const CACHE_KEY = 'disneylandParisShows';
const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 heure

const ShowsAndEvents = ({ updateLoadingState }) => {
  const { t, i18n } = useTranslation();
  const [shows, setShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const fetchShows = useCallback(async () => {
    const startTime = Date.now();
    setLoading(true);
    setError(null);
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cachedTimestamp = localStorage.getItem(`${CACHE_KEY}_timestamp`);
      
      if (cachedData && cachedTimestamp) {
        const now = new Date().getTime();
        if (now - parseInt(cachedTimestamp) < CACHE_EXPIRATION) {
          setShows(JSON.parse(cachedData));
          setLoading(false);
          return;
        }
      }

      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('API response:', data);
      
      const showsData = data.liveData
        .filter(item => item.entityType === 'SHOW')
        .map(show => ({
          id: show.id,
          name: show.name,
          status: show.status,
          showTimes: show.showtimes
            ? show.showtimes.map(st => st.startTime)
            : []
        }));
      
      console.log('Tous les spectacles formatés:', showsData);
      
      setShows(showsData);
      console.log('Shows data set:', showsData);
      
      localStorage.setItem(CACHE_KEY, JSON.stringify(showsData));
      localStorage.setItem(`${CACHE_KEY}_timestamp`, new Date().getTime().toString());
    } catch (error) {
      console.error('Erreur lors de la récupération des spectacles:', error);
      setError(t('common.error'));
    } finally {
      const endTime = Date.now();
      const loadingTime = endTime - startTime;
      const minLoadingTime = 100; // 0.1 seconde en millisecondes
      
      if (loadingTime < minLoadingTime) {
        setTimeout(() => {
          setLoading(false);
        }, minLoadingTime - loadingTime);
      } else {
        setLoading(false);
      }
    }
  }, [t]);

  useEffect(() => {
    fetchShows();
  }, [fetchShows]);

  useEffect(() => {
    updateLoadingState(loading);
    if (!loading) {
      setContentLoaded(true);
    }
  }, [loading, updateLoadingState]);

  const formatTime = (dateString) => {
    if (!dateString) return t('common.notAvailable');
    try {
      const date = new Date(dateString);
      return date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error('Erreur lors du formatage de la date:', error);
      return t('common.notAvailable');
    }
  };

  const getStatusText = (show) => {
    if (show.status === 'CLOSED') return t('closed');
    if (show.status === 'DOWN') return t('outOfOrder');
    if (show.status === 'OPERATING') {
      if (show.showTimes && show.showTimes.length > 0) {
        const nextShowTime = show.showTimes.find(time => new Date(time) > new Date());
        if (nextShowTime) {
          return t('nextShow', { time: formatTime(nextShowTime) });
        }
      }
      return t('open');
    }
    return t('noInfo');
  };

  if (loading) return <p>{t('common.loading')}</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className={`park-content ${contentLoaded ? 'loaded' : ''}`}>
      {!contentLoaded && (
        <div className="loading-overlay">
          <div className="mickey-loading">
            <div className="ear ear-left"></div>
            <div className="ear ear-right"></div>
            <div className="head"></div>
          </div>
        </div>
      )}
      {contentLoaded && (
        <>
          <h2>{t('shows.title')}</h2>
          {error && <div className="error-message">{error}</div>}
          <div className="shows-list">
            {shows.map((show) => (
              <div key={show.id} className="show-item">
                <h3>{show.name}</h3>
                {show.showTimes && show.showTimes.length > 0 ? (
                  <div className="show-times">
                    {show.showTimes.map((showTime, index) => (
                      <span key={index}>{formatTime(showTime)}</span>
                    ))}
                  </div>
                ) : (
                  <p>{t('shows.noShowTimes')}</p>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ShowsAndEvents;