import React, { useState } from 'react';

const CustomPrompt = ({ isOpen, onClose, onSubmit, message }) => {
  const [value, setValue] = useState('');

  if (!isOpen) return null;

  const predefinedTimes = [5, 10, 15, 20];

  return (
    <div className="custom-prompt-overlay">
      <div className="custom-prompt">
        <button 
          onClick={onClose}
          className="close-button"
        >
          ×
        </button>
        <p>{message}</p>
        <div className="predefined-times">
          {predefinedTimes.map(time => (
            <button 
              key={time} 
              onClick={() => onSubmit(time)}
              className="time-button"
            >
              {time} min
            </button>
          ))}
        </div>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Autre temps d'attente"
          className="custom-input"
        />
        <button 
          onClick={() => onSubmit(value)}
          className="confirm-button"
        >
          Confirmer
        </button>
      </div>
    </div>
  );
};

export default CustomPrompt;