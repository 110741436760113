import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

const CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes

const fetchWithBackoff = async (url) => {
  try {
    const response = await fetch(url);
    if (response.status === 429) {
      const retryAfter = response.headers.get('Retry-After');
      const waitTime = retryAfter ? parseInt(retryAfter) * 1000 : 1000;
      console.log(`Rate limit atteint. Attente de ${waitTime / 1000} secondes.`);
      await new Promise(resolve => setTimeout(resolve, waitTime));
      return fetchWithBackoff(url);
    }
    return response;
  } catch (error) {
    console.error('Erreur lors de la requête:', error);
    throw error;
  }
};

const useParkData = (apiUrl, scheduleApiUrl, cacheKey, calendarUrl, updateLoadingState) => {
  const [waitTimes, setWaitTimes] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openingHours, setOpeningHours] = useState({ openingTime: null, closingTime: null });

  const fetchWaitTimes = useCallback(async () => {
    setLoading(true);
    try {
      const cachedData = localStorage.getItem(cacheKey);
      const cachedTimestamp = localStorage.getItem(`${cacheKey}_timestamp`);
      
      if (cachedData && cachedTimestamp) {
        const now = new Date().getTime();
        if (now - parseInt(cachedTimestamp) < CACHE_EXPIRATION) {
          const parsedData = JSON.parse(cachedData);
          setWaitTimes(parsedData.waitTimes);
          setOpeningHours(parsedData.openingHours);
          setLastUpdate(new Date(parseInt(cachedTimestamp)));
          setLoading(false);
          return;
        }
      }

      const [waitTimesResponse, scheduleResponse] = await Promise.all([
        fetchWithBackoff(apiUrl),
        fetchWithBackoff(scheduleApiUrl)
      ]);

      const waitTimesData = await waitTimesResponse.json();
      const scheduleData = await scheduleResponse.json();

      const rides = waitTimesData.liveData.filter(item => item.entityType === 'ATTRACTION');
      const formattedWaitTimes = rides.map(ride => ({
        id: ride.id,
        name: ride.name,
        waitTime: ride.queue?.STANDBY?.waitTime ?? -1,
        status: ride.status
      }));
      
      const today = new Date().toISOString().split('T')[0];
      const todaySchedule = scheduleData.schedule.find(item => item.date === today && item.type === 'OPERATING');
      const openingTime = todaySchedule?.openingTime;
      const closingTime = todaySchedule?.closingTime;
      
      setWaitTimes(formattedWaitTimes);
      setOpeningHours({ openingTime, closingTime });
      const now = new Date();
      setLastUpdate(now);
      
      const cacheData = {
        waitTimes: formattedWaitTimes,
        openingHours: { openingTime, closingTime }
      };
      localStorage.setItem(cacheKey, JSON.stringify(cacheData));
      localStorage.setItem(`${cacheKey}_timestamp`, now.getTime().toString());
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    } finally {
      setLoading(false);
    }
  }, [apiUrl, scheduleApiUrl, cacheKey]);

  const debouncedFetchWaitTimes = useCallback(
    debounce(fetchWaitTimes, 1000),
    [fetchWaitTimes]
  );

  const fetchOpeningHours = useCallback(async () => {
    try {
      const response = await fetch(scheduleApiUrl);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des horaires');
      }
      const data = await response.json();

      // Recherchez les horaires d'ouverture dans le dernier élément du tableau schedule
      const todaySchedule = data.schedule[data.schedule.length - 1];

      if (todaySchedule && todaySchedule.type === 'OPERATING') {
        setOpeningHours({
          openingTime: todaySchedule.openingTime,
          closingTime: todaySchedule.closingTime
        });
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des horaires:', error);
    }
  }, [scheduleApiUrl]);

  useEffect(() => {
    fetchWaitTimes();
    fetchOpeningHours();
    const fetchInterval = setInterval(() => {
      fetchWaitTimes();
    }, 5 * 60 * 1000); // Rafraîchit toutes les 5 minutes

    return () => clearInterval(fetchInterval);
  }, [fetchWaitTimes, fetchOpeningHours]);

  return {
    waitTimes,
    lastUpdate,
    loading,
    openingHours,
    debouncedFetchWaitTimes
  };
};

export default useParkData;
